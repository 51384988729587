@import '../../../../assets/scss/variables.module';

.card {
	padding: 8px 16px;
	min-width: 300px;

	button {
		justify-content: start;
	}

	.avatarContainer {
		height: 64px;

		.upload {
			position: relative;
			top: -33%; //20px;
			left: 66%; //40px;

			.cameraBtn {
				justify-content: center;
			}
		}
	}

	.otherUserAvatar {
		display: flex;
		justify-content: center;

		height: 20px;
		width: 20px;
		font-size: 16px;
	}

	.mainUserAvatar {
		display: flex;
		height: 64px;
		width: 64px;

		align-items: center;
		justify-content: center;

		:global(.ant-avatar-string) {
			transform: scale(2) translateX(-50%) !important;
		}
	}

	.userInfoButton {
		width: 100%;
	}

	.userInfoSpace {
		gap: 4px !important;
	}

	.divider {
		margin: 2px 0;
	}

	.userName {
		font-weight: 500;
		color: $neutral-10;
	}

	.userEmail {
		color: $neutral-9;
	}
}
