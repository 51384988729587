@import '../../assets/scss/variables.module';

.layout {
  background-color: #f5f7f7;
}

.header {
  z-index: 99;
  position: -webkit-sticky;
  position: sticky;

  top: 16px;
  margin: 16px 0 !important;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
}