@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import './variables.module';

* {
    font-family: 'Inter', sans-serif !important;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px $neutral-2 inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: $neutral-10 !important;
}

// Helpers
.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.d-none {
    display: none;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-around {
    justify-content: space-around;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: end;
}

.align-items-baseline {
    align-items: baseline;
}