@import '../../../assets/scss/variables.module';

.popover {
	:global(.ant-popover-inner) {
		padding: 8px !important;
	}
}

.avatar {
	:global(.ant-avatar-string) {
		transform: scale(1.5) translateX(-50%) !important;
	}

	&:hover {
		cursor: pointer;
	}
}
