/* Primary colors */

$primary-1: #E7FBF9;
$primary-2: #8BD9D6;
$primary-3: #62CCCC;
$primary-4: #3DBBBF;
$primary-5: #1DA9B3;
$primary-6: #0096A6;
$primary-7: #006E80;
$primary-8: #004A59;
$primary-9: #002933;
$primary-10: #000A0D;

/* Polar Green */

$green-1: #F6FFED;
$green-2: #D9F7BE;
$green-5: #73D13D;
$green-6: #52C41A;
$green-7: #389E0D;

/* Dust Red */

$red-1: #FFF1F0;
$red-2: #FFCCC7;
$red-4 : #FF7875;
$red-5: #FF4D4F;
$red-6: #F5222D;
$red-7: #CF1322;

/* Calendula Gold */

$gold-1: #FFFBE6;
$gold-2: #FFF1B8;
$gold-6: #FAAD14;
$gold-7: #D48806;
$gold-8: #AD6800;

/* Daybreak Blue */

$blue-6: #1890FF;

/* Neutral colors */

$neutral-1: #FAFDFF;
$neutral-2: #f2f9ff;
$neutral-3: #E9F1F7;
$neutral-4: #DDE4EB;
$neutral-5: #D1D7DE;
$neutral-6: #C5CBD1;
$neutral-7: #B9BEC4;
$neutral-8: #94A3B8;
$neutral-9: #6D7B91;
$neutral-10: #4B566B;
$white: #FFF;

$danger-1: $red-1;
$danger-4: $red-4;
$danger-5: $red-5;
$danger-6: $red-6;
$danger-7: $red-7;


$text-disabled: rgba(0, 0, 0, 0.45);
$disabled-and-placeholder: $neutral-8;
$page-background: #F5F7FC;

$menu-side-1: rgba(0, 150, 166, 0.05);
$menu-side-2: #CDE7EC;

:export {
  // Primary
  primary: $primary-6;
  primaryText: $neutral-10;
  primaryHover: $primary-4;
  primaryActive: $primary-7;

  // Error
  colorError: $danger-6;
  colorErrorHover: $danger-4;
  colorErrorActive: $danger-7;
  colorErrorBorder: $danger-6;
  colorErrorBorderHover: $danger-4;

  colorBgLayout: $neutral-3;

  // Button
  buttonBgContainer: $neutral-1;

  // Input
  inputBorder: $neutral-2;
  inputBgContainer: $neutral-2;
  inputFill: $neutral-2;
  inputFillAlter: $neutral-2;

  // Text
  colorText: $neutral-10;
  textSecondary: $neutral-8;
  textDisabled: $disabled-and-placeholder;
  textPlaceholder: $disabled-and-placeholder;
  textLabel: $neutral-10;
  textHeading: $neutral-10;

  white: $white;
  red: $red-6;
  blue: $blue-6;
  gold: $gold-6;
  green: $green-6;
  danger: $danger-6;
}